import BaseService, { APIResponse } from "./BaseService";

export type SportResponse = {
    'id':number,
    'nome':string,
    'num_giocatori':number[]
    'prezzo':number
    'minuti':number
}[];

export type SportResponseArray = {
    'id':number,
    'nome':string,
    'num_giocatori':number[]
    'prezzo':number
    'minuti':number
    'attivo': boolean
}[]

export default class SportService extends BaseService {
    static async ListaSport(): Promise<APIResponse<SportResponse>> {
        return await this.perform<SportResponse>({
            url: "operatore/sport",
            method: "GET"
        });
    }

    static async ListaSportStruttura(idStruttura: number): Promise<APIResponse<SportResponseArray>> {
        return await this.perform<SportResponseArray>({
            url: "operatore/sport/" + idStruttura,
            method: "GET"
        });
    }

}
