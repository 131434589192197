import BaseService, { APIResponse } from "./BaseService";

export type ScontiResponse = {
  id: number,
  codice_sconto: string,
  valore_sconto: number ,
  tipo_sconto:{
    descrizione: string,
    id:number
  },
  tipo_sconto_buono:{
    descizione:string
  }
  utenteId: number,
  strutturaId: number,
  count_eventi: number,
  max_eventi: number,
  sportId: number,
  start_time: string,
  expire_time:string
  id_sconto: string,
  num_sconti: number
};

interface x {
  id: number
}

export interface ScontiRequest{
  codice_sconto:string,
  valore_sconto:number,
  utente: {
    id:number | null
  },
  tipo_sconto_buono:{
    id:number | null
  }
  tipo_sconto:{
    id:number
  },
  max_eventi:number,
  start_time:string,
  expire_time:string,
  sport: x | null,
  struttura:{
    id:number
  }
}

export type TipiSconti ={
  id: number
  descrizione: string,
  commento: string
}[]

export default class ScontiService extends BaseService {
  static async ListaSconti(idStruttura: number, id_tipo_sconto_buono: number): Promise<APIResponse<ScontiResponse[]>> {
    return await this.perform<ScontiResponse[]>({
      url:"operatore/sconti?id_struttura="+idStruttura+"&id_tipo_sconto_buono="+id_tipo_sconto_buono,
      method: "GET"
    })
  }
  static async ListaScontiId(idStruttura: number, id_tipo_sconto_buono: number, id_sconto: string): Promise<APIResponse<ScontiResponse[]>> {
    return await this.perform<ScontiResponse[]>({
      url:"operatore/sconti/"+ id_sconto +"?id_struttura="+idStruttura+"&id_tipo_sconto_buono="+id_tipo_sconto_buono,
      //params: {"id_sconto": id_sconto},
      method: "GET"
    })
  }
  static async PushSconto(sconto:ScontiRequest): Promise<APIResponse<ScontiResponse[]>>{
    return await this.perform<ScontiResponse[]>({
      url:"operatore/sconti",
      method:"post",
      params:{"num_sconti": 1},
      body: sconto
    })
  }
  static async ListaTipiSconto(): Promise<APIResponse<TipiSconti>>{
    return await this.perform<TipiSconti>( {
      url:"operatore/tipo_sconto",
      method:"GET"
    })
  }
  static async PushCodiciSconto(sconto:ScontiRequest, numSconti: number): Promise<APIResponse<ScontiResponse[]>>{
    return await this.perform<ScontiResponse[]>({
      url:"operatore/sconti",
      method:"post",
      params:{"num_sconti": numSconti},
      body: sconto
    })
  }
  static async DeleteSconto(idSconto: number, idStruttura: number): Promise<APIResponse<ScontiResponse[]>>{
    return await this.perform<ScontiResponse[]>({
      url:"operatore/sconti/"+idSconto,
      method: "PUT",
      params:{"id_struttura": idStruttura},
    })
  }
  static async ModificaSconto(sconto:any): Promise<APIResponse<ScontiResponse[]>>{
    return await this.perform<ScontiResponse[]>({
      url:"operatore/sconti/modifica",
      method:"post",
      body: sconto
    })
  }

}

