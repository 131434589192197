


















































































































































































import {Component, Emit, Prop, Vue, Watch} from "vue-property-decorator";
import PromozioniService, {PromozioniResponse} from "@/services/PromozioniService";
import ScontiService, {ScontiResponse, TipiSconti} from "@/services/ScontiService";
import SportService, {SportResponse} from "@/services/SportService";
import  {DateTime} from "luxon";

import editServiziCampo from "@/components/editServiziCampo.vue";
import UtentiService from "@/services/UtentiService";

class Promo {
  sport: number
  giorniSelezionati: number[]
  date: Date[]
  startTime: string
  endTime: string
  pagamentoOnline: boolean
  tipoPromo: number
  sconto: number
  descrizione: string

  public constructor() {
    this.sport = 0
    this.giorniSelezionati = [0,1,2,3,4,5,6]
    this.date = [new Date(), new Date()]
    this.startTime = ''
    this.endTime = ''
    this.pagamentoOnline = false
    this.tipoPromo = 0
    this.sconto = 0
    this.descrizione = ''
  }
}

class Sconto {
  sport: number
  tipoSconto: number
  tipo_sconto_buono: number
  sconto: number
  codiceSconto: string
  numeroUtilizzi: number
  date: Date[]

  public constructor() {
    this.sport = 0
    this.tipoSconto = 0
    this.tipo_sconto_buono = 0
    this.sconto = 0
    this.codiceSconto = ''
    this.numeroUtilizzi = 0
    this.date = [new Date(), new Date()]
  }
}

interface ScontoInviato {
  codice_sconto: string
  count_eventi: number
  expire_time: string
  id: number
  id_sconto: string
  max_eventi: number
  num_sconti: number
  sport: {
    id: number
    nome: string
  }
  start_time: string
  tipo_sconto: {
    descrizione: string
    id: number
  }
  tipo_sconto_buono: {
    descrizione: string
    id: number
  }
  utente: {
    email: string
  }
  valore_sconto: 1
}
@Component
export default class editDiscount extends Vue{
  @Prop() tipo_discount !: string
  @Prop() id_struttura !: number
  @Prop() id_discount !: number
  @Prop() isVisible !: boolean
  @Prop() promozione !: PromozioniResponse
  @Prop() sconto !: ScontoInviato
  public listaSport: any = []
  public sport :any
  public test: number = 0
  public nuovaPromo: Promo = new Promo()
  public nuovoSconto: Sconto = new Sconto()
  public tipiSconti: TipiSconti = []

  giorniSettimana = [false, false, false, false, false, false, false]

  giorniOptions = [
    {"id": 0, "label": "Lunedì"},
    {"id": 1, "label": "Martedì"},
    {"id": 2, "label": "Mercoledì"},
    {"id": 3, "label": "Giovedì"},
    {"id": 4, "label": "Venerdì"},
    {"id": 5, "label": "Sabato"},
    {"id": 6, "label": "Domenica"}
  ];

  tipoOptions = [
    {"id": 1, "label": "Percentuale"},
    {"id": 2, "label": "In Euro"},
  ]

  public async RiceviSport(){
    const response = await SportService.ListaSportStruttura(this.id_struttura!)
    this.listaSport = response.data.map(x => {
      return {
        nome: x.nome,
        id: x.id,
      }
    })
    this.listaSport.push({
        nome: "tutti gli sport",
        id: 0
    })
  }
  @Watch("isVisible")
  public async isVisibleMod()
  {
    if(this.isVisible){
      if(this.tipo_discount === "promo"){
        this.nuovaPromo.sport = 0
        if(!this.promozione.sport){
          this.nuovaPromo.sport = 0
        }else{
          for(const sport of this.listaSport){
            if(sport.nome === this.promozione.sport.nome){
              this.nuovaPromo.sport = sport.id
              break;
            }
          }
        }

        this.nuovaPromo.giorniSelezionati.splice(0,this.nuovaPromo.giorniSelezionati.length)
        for(let i = 0; i<this.promozione.giorno_settimana.length; i++){
          if(this.promozione.giorno_settimana[i])
            this.nuovaPromo.giorniSelezionati[i] = i
        }

        this.nuovaPromo.date .splice(0,this.nuovaPromo.date .length)
        this.nuovaPromo.date = [new Date(DateTime.fromFormat(this.promozione.start_time,'dd/MM/yyyy').toISO()),
          new Date(DateTime.fromFormat(this.promozione.expire_time,'dd/MM/yyyy').toISO())]
        this.nuovaPromo.startTime = this.promozione.ora_inizio_promozione
        this.nuovaPromo.endTime = this.promozione.ora_fine_promozione
        this.nuovaPromo.pagamentoOnline = this.promozione.pagamento_online
        this.nuovaPromo.tipoPromo = this.promozione.tipo_promozione.id
        this.nuovaPromo.sconto = this.promozione.valore_promozione
        this.nuovaPromo.descrizione = this.promozione.des_promozione

      } else {

        this.nuovoSconto.sport = 0
        if(!this.sconto.sport){
          this.nuovoSconto.sport = 0
        }else{
          for(const sport of this.listaSport){
            if(sport.nome === this.sconto.sport.nome){
              this.nuovoSconto.sport = sport.id
              break;
            }
          }
        }
        this.nuovoSconto.tipoSconto = this.sconto.tipo_sconto.id
        this.nuovoSconto.sconto = this.sconto.valore_sconto
        this.nuovoSconto.codiceSconto = this.sconto.codice_sconto
        this.nuovoSconto.numeroUtilizzi = this.sconto.max_eventi
        this.nuovoSconto.tipo_sconto_buono = this.sconto.tipo_sconto_buono.id
        this.nuovoSconto.date .splice(0,this.nuovoSconto.date.length)
        this.nuovoSconto.date = [new Date(DateTime.fromFormat(this.sconto.start_time,'dd/MM/yyyy').toISO()),
          new Date(DateTime.fromFormat(this.sconto.expire_time,'dd/MM/yyyy').toISO())]
      }

    }
  }

  public datePickerOptions: any = {
    disabledDate(date: any) {
      return date < new Date().setDate(new Date().getDate() - 1)
    }
  }

  async modifica(){
    if(this.tipo_discount === "promo"){
      this.giorniSettimana = [false, false, false, false, false, false, false]
      for (let i = 0; i < this.nuovaPromo.giorniSelezionati.length; i++) {
        this.giorniSettimana[this.nuovaPromo.giorniSelezionati[i]] = true
      }
      const promoAggiornata: any = {
        id: this.promozione.id,
        des_promozione: this.nuovaPromo.descrizione,
        valore_promozione: this.nuovaPromo.tipoPromo == 2 ? this.nuovaPromo.sconto*100 : this.nuovaPromo.sconto,
        tipo_promozione: {
          id: this.nuovaPromo.tipoPromo
        },
        sport: this.nuovaPromo.sport != 0 ?{
          id: this.nuovaPromo.sport
        }: null,
        pagamento_online: this.nuovaPromo.pagamentoOnline,
        giorno_settimana: this.giorniSettimana,
        ora_inizio_promozione: this.nuovaPromo.startTime,
        ora_fine_promozione: this.nuovaPromo.endTime,
        start_time: this.nuovaPromo.date[0],
        expire_time: this.nuovaPromo.date[1]
      }
      const response = await PromozioniService.ModificaPromozione(promoAggiornata, this.id_struttura)
      if(response.error){
        this.$message.error(response.msg)
      } else {
        this.$message.success("modifica avvenuta con successo")
        this.CloseDialog()
      }
    } else {
      const idUtente = await UtentiService.VerificaUtente(this.sconto.utente.email)
      const scontoAggiornato: any = {
        id : this.sconto.id,
        codice_sconto : this.nuovoSconto.codiceSconto,
        tipoScontoId : this.nuovoSconto.tipoSconto,
        utenteId : idUtente.data.id,
        struttura : {
          id: this.id_struttura
        },
        count_eventi : this.sconto.count_eventi,
        max_eventi : this.nuovoSconto.numeroUtilizzi,
        sport : {
          id: this.nuovoSconto.sport
        },
        start_time : this.nuovoSconto.date[0],
        expire_time : this.nuovoSconto.date[1],
        tipoScontoBuonoId : this.nuovoSconto.tipo_sconto_buono,
        valore_sconto : this.nuovoSconto.tipoSconto == 2 ? this.nuovoSconto.sconto*100 : this.nuovoSconto.sconto,
        num_sconti : 1,
        id_sconto : this.sconto.id_sconto
      }
      const response = await ScontiService.ModificaSconto(scontoAggiornato)
      if(response.error){
        this.$message.error(response.msg)
      } else {
        this.$message.success("modifica avvenuta con successo")
        this.CloseDialog()
      }
    }
  }

  resetImporto() {
    this.nuovaPromo.sconto = 1;
    this.nuovaPromo.sconto = 1;
  }

  public async RiceviTipiSconti() {
    try {
      const response = await ScontiService.ListaTipiSconto();

      if (response.error) {
        this.$message.error(response.msg)
      } else {
        this.tipiSconti = response.data
      }

      for (let i = 0; i < this.tipiSconti.length; i++) {
        if (this.tipiSconti[i].id == 2) {
          this.tipiSconti.splice(i, 1)
        }
      }
    } catch (err: any) {

    }
  }
  @Emit()
  CloseDialog() {
    this.$emit("CloseDialog")
  }

   async mounted() {
     await this.RiceviTipiSconti()
     await this.RiceviSport()
     if(this.tipo_discount === "promo"){
       if(!this.promozione.sport){
         this.nuovaPromo.sport = 0
       }else{
         for(const sport of this.listaSport){
           if(sport.nome === this.promozione.sport.nome){
             this.nuovaPromo.sport = sport.id
             break;
           }
         }
       }
       this.nuovaPromo.giorniSelezionati.splice(0,this.nuovaPromo.giorniSelezionati.length)
       for(let i = 0; i<this.promozione.giorno_settimana.length; i++){
         if(this.promozione.giorno_settimana[i])
           this.nuovaPromo.giorniSelezionati[i] = i
       }
       this.nuovaPromo.date .splice(0,this.nuovaPromo.date .length)
       this.nuovaPromo.date = [new Date(DateTime.fromFormat(this.promozione.start_time,'dd/MM/yyyy').toISO()),
         new Date(DateTime.fromFormat(this.promozione.expire_time,'dd/MM/yyyy').toISO())]
       this.nuovaPromo.startTime = this.promozione.ora_inizio_promozione
       this.nuovaPromo.endTime = this.promozione.ora_fine_promozione
       this.nuovaPromo.pagamentoOnline = this.promozione.pagamento_online
       this.nuovaPromo.tipoPromo = this.promozione.tipo_promozione.id
       this.nuovaPromo.sconto = this.promozione.valore_promozione
       this.nuovaPromo.descrizione = this.promozione.des_promozione

     } else {

       if(!this.sconto.sport){
         this.nuovoSconto.sport = 0
       }else{
         for(const sport of this.listaSport){
           if(sport.id === this.sconto.sport.id){
             this.nuovoSconto.sport = sport.id
             break;
           }
         }
       }
       this.nuovoSconto.tipoSconto = this.sconto.tipo_sconto.id
       this.nuovoSconto.sconto = this.sconto.valore_sconto
       this.nuovoSconto.codiceSconto = this.sconto.codice_sconto
       this.nuovoSconto.numeroUtilizzi = this.sconto.max_eventi
       this.nuovoSconto.tipo_sconto_buono = this.sconto.tipo_sconto_buono.id
       this.nuovoSconto.date .splice(0,this.nuovoSconto.date.length)
       this.nuovoSconto.date = [new Date(DateTime.fromFormat(this.sconto.start_time,'dd/MM/yyyy').toISO()),
         new Date(DateTime.fromFormat(this.sconto.expire_time,'dd/MM/yyyy').toISO())]
     }
   }
}
