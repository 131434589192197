<template>
  <div class="toolbar-grid">
    <div class="toolbar-flex flex-left">
      <slot name="left" />
    </div>
    <!--<div class="toolbar-flex flex-center">
      <slot name="center" />
    </div>-->
    <div class="toolbar-flex flex-right">
      <slot name="right" />
    </div>
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";

@Component
export default class Toolbar extends Vue {}
</script>

<style scoped>
.toolbar-grid {
  display: grid;
  grid-template-columns: 1fr auto;
}

.toolbar-flex {
  display: flex;
  align-items: center;
}

.flex-center {
  justify-content: center;
}

.flex-right {
  justify-content: flex-end;
}

@media only screen and (max-width: 600px){
  .toolbar-grid{
    grid-template-columns: none;
    grid-template-rows: auto auto;
    row-gap: 10px;
  }

  .flex-right{
    justify-content: center;
  }
}

</style>