import BaseService, { APIResponse } from "./BaseService";

export type UtentiResponse = {
  id: number
};

export type utentiResponse = {
  id: number,
  email: string,
  cellulare: string,
  cognome: string,
  nome:string

}[];

export default class UtentiService extends BaseService {

  static async VerificaUtente(email: string | null): Promise<APIResponse<UtentiResponse>> {
    return await this.perform<UtentiResponse>({
      url: "operatore/utenti/checkemail",
      method: "GET",
      params: {"email": email}
    })
  }

  static async getUtenti(filtro: string): Promise<APIResponse<utentiResponse>> {
    return await this.perform<utentiResponse>({
      url: "operatore/utenti",
      method: "GET",
      params: {
        'filtro': filtro
      }
    });
  }

  static async getUtenteById(utenteId: number): Promise<APIResponse<utentiResponse>> {
    return await this.perform<utentiResponse>({
      url: "operatore/utenti/" + utenteId,
      method: "get"
    })
  }
}
