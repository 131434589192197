
export class Filtro {
    from_date: any;
    to_date: any;
    id_campo: any;
    id_struttura:any;
    id_sport:any;

    constructor() {
        this.from_date = null;
        this.to_date = null;
        this.id_campo = null;
        this.id_struttura = null;
        this.id_sport = null;
    }

}
