


















































































































































































































import {Component, Vue, Watch} from "vue-property-decorator";
import Toolbar from "@/components/Toolbar.vue";
import ScontiService, {ScontiResponse} from "@/services/ScontiService";
import  {DateTime} from "luxon";
import PromozioniService, {PromozioniResponse} from "@/services/PromozioniService";
import StruttureService, {StruttureResponse} from "@/services/StruttureService";
import {Filtro} from "@/Classes/FiltroClass";
import EditDiscount from "@/components/editDiscount.vue";

@Component({
  components: {
    EditDiscount,
    Toolbar,
  },
})
export default class Discounts extends Vue {
  filter: "promo" | "discounts" | "coupon"= "promo";
  public sconti:ScontiResponse[] = []
  public coupon:ScontiResponse[] = []
  public dettaglioCoupon: ScontiResponse[] = []
  public promos:PromozioniResponse[] = []
  public datiStruttura: StruttureResponse = []
  public filtro = new Filtro();
  public dialogAddVisible: boolean = false;
  public dialogModificaVisible: boolean = false;
  public idDiscount: number = -1
  public discountSelezionato: any = null


  public giorniSettimana: string[] = ["Lun", "Mar", "Mer", "Gio", "Ven", "Sab", "Dom"]
  public giorni: string[] = []
  public search: string = ""
  public page: number = 1
  public pageSize: number = 7

  get showPromo(): number {
    if(this.filter === "promo")
      return 1
    else if(this.filter === "discounts")
      return 2
    else
      return 3
  }
  TestoButtonAddDiscount(){
    if(this.filter === 'promo')
      return 'Aggiungi Promozione'
    else if (this.filter === 'discounts' )
      return 'Aggiungi Coupon'
    else
      return 'Aggiungi Sconto'
  }
  addDiscount(){
    this.$router.push("/addDiscount?filter="+this.filter);
  }

  public async RiceviStrutture(){
    try{
      const response = await StruttureService.getStrutture();

      if(response.error) {
        this.$message.error(response.msg)
      } else{
        this.datiStruttura = response.data;
        this.filtro.id_struttura = this.datiStruttura[0].struttura.value
      }
    }
    catch (err:any)
    {

    }
  }

  public async RiceviDiscounts() {
    try {
      const sconti1Response = await ScontiService.ListaSconti(this.filtro.id_struttura, 1);
      if (sconti1Response.error) {
        this.$message.error(sconti1Response.msg)
      } else {
        this.sconti = sconti1Response.data;
      }

      const promosResponse = await PromozioniService.ListaPromozioni(this.filtro.id_struttura);
      if (promosResponse.error) {
        this.$message.error(promosResponse.msg)
      } else {
        this.promos = promosResponse.data;
      }

      const sconti2Response = await ScontiService.ListaSconti(this.filtro.id_struttura, 3);
      if (sconti2Response.error) {
        this.$message.error(sconti2Response.msg)
      } else {
        if (this.sconti.length > 0) {
          for (let sconto of sconti2Response.data)
            this.sconti.push(sconto)
        } else
          this.sconti = sconti2Response.data;
      }

      const couponResponse = await ScontiService.ListaSconti(this.filtro.id_struttura, 2);
      if (couponResponse.error) {
        this.$message.error(couponResponse.msg)
      } else {
        this.coupon = couponResponse.data;
      }

      for (let i = 0; i < this.sconti.length; i++) {
        this.sconti[i].start_time = DateTime.fromISO(this.sconti[i].start_time).toFormat("dd/MM/yyyy")
        this.sconti[i].expire_time = DateTime.fromISO(this.sconti[i].expire_time).toFormat("dd/MM/yyyy")

        if (this.sconti[i].tipo_sconto.id === 2) {
          this.sconti[i].valore_sconto /= 100;
        }
      }

      this.giorni = []
      for (let i = 0; i < this.promos.length; i++) {
        this.promos[i].start_time = DateTime.fromISO(this.promos[i].start_time).toFormat("dd/MM/yyyy")
        this.promos[i].expire_time = DateTime.fromISO(this.promos[i].expire_time).toFormat("dd/MM/yyyy")

        if (this.promos[i].tipo_promozione.id === 2) {
          this.promos[i].valore_promozione /= 100;
        }

        for (let y = 0; y < this.promos[i].giorno_settimana.length; y++) {
          if (this.promos[i].giorno_settimana[y]) {
            if (this.giorni[i] == null)
              this.giorni[i] = this.giorniSettimana[y]
            else
              this.giorni[i] = this.giorni[i] + ", " + this.giorniSettimana[y]
          }
        }
      }
      for (let i = 0; i < this.coupon.length; i++) {
        this.coupon[i].start_time = DateTime.fromISO(this.coupon[i].start_time).toFormat("dd/MM/yyyy")
        this.coupon[i].expire_time = DateTime.fromISO(this.coupon[i].expire_time).toFormat("dd/MM/yyyy")

        if (this.coupon[i].tipo_sconto.id === 2) {
          this.coupon[i].valore_sconto /= 100;
        }
      }
    }
    catch (err:any)
    {

    }
  }

  modifica(scope: any){
    this.dialogModificaVisible = true
    this.idDiscount = scope.id
    this.discountSelezionato = scope

  }

  openPromozione( scope:PromozioniResponse){
      this.$confirm("Una volta eliminata questa promozione non sarà più visualizzabile e utilizzabile", "Sei sicuro di voler eliminare la promozione?", {
        confirmButtonText: "Conferma",
        cancelButtonText: "Indietro"
      }).then(() => {
        this.EliminaPromozione(scope)
      }).catch(() => {
        this.$message({
          type: 'warning',
          message: 'Lo sconto non è stato creato'
        })
      })
  }

  openSconti( scope: ScontiResponse) {
      this.$confirm("Una volta eliminato questo sconto non sarà più visualizzabile e utilizzabile", "Sei sicuro di voler eliminare lo sconto?", {
        confirmButtonText: "Conferma",
        cancelButtonText: "Indietro"
      }).then(() => {
        this.EliminaSconto(scope)
      }).catch(() => {
        this.$message({
          type: 'warning',
          message: 'Lo sconto non è stato creato'
        })
      })
  }

  async allSconti(scope: any) {
    const response = await ScontiService.ListaScontiId(this.filtro.id_struttura, 2, scope.id_sconto)
    if(response.error){
      this.$message.error(response.msg)
    } else {
      this.dettaglioCoupon = response.data
      for (let i = 0; i < this.dettaglioCoupon.length; i++) {
        this.dettaglioCoupon[i].start_time = DateTime.fromISO(this.dettaglioCoupon[i].start_time).toFormat("dd/MM/yyyy")
        this.dettaglioCoupon[i].expire_time = DateTime.fromISO(this.dettaglioCoupon[i].expire_time).toFormat("dd/MM/yyyy")

        if (this.dettaglioCoupon[i].tipo_sconto.id === 2) {
          this.dettaglioCoupon[i].valore_sconto /= 100;
        }
      }
      this.dialogAddVisible = true
    }
  }

  async EliminaPromozione(row: PromozioniResponse){
    const response = await PromozioniService.DeletePromozione(row.id, this.filtro.id_struttura)
    if(response.error) {
      this.$message.error(response.msg)
    } else {
      this.$message.success("Eliminazione avenuta");
      await this.RiceviDiscounts()
    }
  }

  async EliminaSconto(row: ScontiResponse){
    const response = await ScontiService.DeleteSconto(row.id, this.filtro.id_struttura)
    if(response.error) {
      this.$message.error(response.msg)
    } else {
      this.$message.success("Eliminazione avenuta");
      if(this.filter === "coupon")
        await this.allSconti(row)
      this.RiceviDiscounts()
    }
  }

  public pagedTableData(){
    if(this.filter === "promo"){
      return this.promos.filter(data => !this.search || data.des_promozione.toLowerCase().includes(this.search.toLowerCase()))
          .slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
    } else if (this.filter === "discounts") {
      return this.sconti.filter(data => !this.search || data.codice_sconto.toLowerCase().includes(this.search.toLowerCase()))
          .slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
    } else {
      return this.coupon.filter(data => !this.search || data.codice_sconto.toLowerCase().includes(this.search.toLowerCase()))
          .slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
    }
  }

  public pagedTableSearch(){
    if(this.filter === "promo"){
      return this.promos.filter(data => !this.search || data.des_promozione.toLowerCase().includes(this.search.toLowerCase()))
    } else if (this.filter === "discounts") {
      return this.sconti.filter(data => !this.search || data.codice_sconto.toLowerCase().includes(this.search.toLowerCase()))
    } else {
      return this.coupon.filter(data => !this.search || data.codice_sconto.toLowerCase().includes(this.search.toLowerCase()))
    }
  }

  public setPage (val: number) {
    this.page = val
  }

  @Watch ('dialogModificaVisible')
  async reloadData(){
    if(!this.dialogModificaVisible){
      await this.RiceviDiscounts()
    }
  }

  @Watch ('filter')
  resetPage(newVal: any){
    this.setPage(1)
  }
  async mounted(){
    await this.RiceviStrutture()
    await this.RiceviDiscounts()
  }

}
