import BaseService, { APIResponse } from "./BaseService";
import {ScontiRequest, ScontiResponse} from "@/services/ScontiService";

export type PromozioniResponse= {
  id:number,
  giorno_settimana:boolean[],
  ora_inizio_promozione: string,
  ora_fine_promozione: string,
  start_time:string,
  expire_time:string,
  des_promozione:string,
  valore_promozione: number,
  pagamento_online:boolean,
  tipo_promozione:{
    id:number,
    descrizione:string
  },
  sport:{
    nome:string
  }
};

interface x {
  id: number
}

export interface PromozioniRequest{
  giorno_settimana:boolean[]
  ora_inizio_promozione:string
  ora_fine_promozione:string
  start_time:string
  expire_time:string
  des_promozione:string
  valore_promozione:number
  pagamento_online:boolean
  sport: x | null
  tipo_promozione:{
    id:number
  },
  id_struttura:number
}

export default class PromozioniService extends BaseService {

  static async ListaPromozioni(idStruttura:number): Promise<APIResponse<PromozioniResponse[]>> {
    return await this.perform<PromozioniResponse[]>({
      url:"operatore/promozioni?id_struttura="+idStruttura,
      method: "GET"
    })
  }
  static async PushPromozione(promo:PromozioniRequest): Promise<APIResponse<PromozioniResponse[]>>{
    return await this.perform<PromozioniResponse[]>({
      url:"operatore/promozioni?id_struttura="+promo.id_struttura,
      method:"post",
      body: promo
    })
  }

  static async ModificaPromozione(promo:any, id_struttura: number): Promise<APIResponse<PromozioniResponse[]>>{
    return await this.perform<PromozioniResponse[]>({
      url:"operatore/promozioni/modifica?id_struttura="+id_struttura,
      method:"post",
      body: promo
    })
  }

  static async DeletePromozione(idPromozione: number, idStruttura:number): Promise<APIResponse<PromozioniResponse[]>>{
    return await this.perform<PromozioniResponse[]>({
      url:"operatore/promozioni/"+ idPromozione,
      params:{"id_struttura": idStruttura},
      method:"PUT"
    })
  }
}
